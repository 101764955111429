import React, { useState, useEffect, useRef } from "react";
import TicketCreateModal from "./Components/TicketCreateModal/TicketCreateModal";
import "./AdminShipments.css";
import InvoiceModal from "../../../Components/InvoiceModal/InvoiceModal";
import Confirm from "../../../Components/Popups/Confirm";
import {
  MdOutlineCancel,
  MdComment,
  MdOutlineStickyNote2,
} from "react-icons/md";

import {
  fetchAllShipmentsAdmin,
  cancelShipment,
} from "../../../services/AdminServices/ShipmentsAdmin/carriers.services";

import Tooltip from "../../../Components/Tooltip/Tooltip";
import Snackbar from "../../../Components/SnackBar/SnackBar";
import BoltTable from "../../../Components/BoltTable/BoltTable";
import Expandable from "./Components/Expandable/Expandable";
import StatusIcons from "../../../Components/StatusIcons/StatusIcons";
import { useNavigate } from "react-router-dom";
import TabsComponent from "../../../Components/Common/TabsComponent";

const AdminShipments = () => {
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [cancelingShipment, setCancelingShipment] = useState(false);
  const [tab, setTab] = useState("all");
  const [ticketCreateModal, setTicketCreateModal] = useState(false);

  const Navigate = useNavigate();

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });
  const handleCancelShipment = async (id) => {
    setCancelingShipment(true);
    const response = await cancelShipment(id);

    if (!response.error) {
      setShipments((prevShipments) => {
        return {
          ...prevShipments,
          data: prevShipments?.data?.map((shipment) => {
            if (shipment?.id === id) {
              return {
                ...shipment,
                shipment_status: response?.data?.result?.shipment_status,
                shipment_histories: [
                  ...shipment?.shipment_histories,
                  response?.data?.result?.shipment_histories,
                ],
              };
            } else {
              return shipment;
            }
          }),
        };
      });
      setCancelingShipment(false);
      setConfirmCancel(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Shipment cancelled successfully.",
          severity: "success",
        };
      });
    } else {
      setCancelingShipment(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Couldn't cancel this shipment!",
          severity: "error",
        };
      });
    }
  };
  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 1,
      label: "Carrier",
      field_name: "carrier_name",
      searchable: true,
      render: (row) => (
        <>
          <img src={row?.shipping_company?.shipping_company?.logo_url} />
        </>
      ),
      width: "200px",
      fixed: "left",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 2,
      label: "Client",
      field_name: "client",
      searchable: true,
      render: (row) => <>{row?.user?.username ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 3,
      label: "Shipment Status",
      field_name: "shipment_status",
      searchable: true,
      render: (row) => <StatusIcons status={row?.shipment_status} />,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 4,
      label: "Admin Invoice Status",
      field_name: "admin_invoice_status",
      searchable: true,
      render: (row) => (
        <StatusIcons
          status={
            row?.admin_invoice_status === null
              ? "Unpaid"
              : row?.admin_invoice_status
          }
        />
      ),
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 5,
      label: "Pickup ID",
      field_name: "PickupID",
      searchable: true,
      render: (row) => <>{row?.PickupID ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 6,
      label: "Shipment Type",
      render: (row) => <>{row?.shipment_type ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 7,
      label: "Bolt Tracking ID",
      render: (row) => <>{row?.tracking_id ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 8,
      label: "Carrier Tracking ID",
      render: (row) => (
        <>{tab === 2 ? row?.id ?? "-" : row?.shipment_tracking_id ?? "-"}</>
      ),
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 9,
      label: "Packages",
      render: (row) => <>{row?.shipment_details?.length ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 10,
      label: "Weight",
      render: (row) => <>{row?.total_weight ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 11,
      label: "Total",
      render: (row) => (
        <>
          {" "}
          <div>
            <div
              className={`${
                row?.shipment_charges?.length > 1
                  ? "shipments__total_updatedamount"
                  : ""
              }`}
            >
              {row.Total ?? "-"}{" "}
              <sup>
                {row?.shipment_charges?.length > 1
                  ? `*${row?.shipment_charges?.length}`
                  : ""}
              </sup>
            </div>

            <div className="shipments__total_prevamount">
              {row?.shipment_charges?.length > 1
                ? row?.shipment_charges[row?.shipment_charges?.length - 2]
                    ?.total
                : ""}
            </div>
          </div>
        </>
      ),
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 12,
      label: "Created at",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
    {
      id: 13,
      label: "Actions",
      render: (row) => (
        <div className="table_action__wrap">
          <Tooltip title="Tickets">
            <div
              className="table_action__btn"
              onClick={() => {
                handleTicketOpen(row);
              }}
              style={{
                backgroundColor:
                  row?.tickets?.length > 0 &&
                  (row?.tickets[0]?.status == "Open" ||
                    row?.tickets[0]?.status == "Dispute" ||
                    row?.tickets[0]?.status == "Disputed")
                    ? "red"
                    : "none",
                color:
                  row?.tickets?.length > 0 &&
                  (row?.tickets[0]?.status == "Open" ||
                    row?.tickets[0]?.status == "Dispute" ||
                    row?.tickets[0]?.status == "Disputed")
                    ? "white"
                    : "none",
              }}
            >
              <MdComment size={10} />
              Ticket
            </div>
          </Tooltip>
          <Tooltip title="View Invoice">
            <div
              className="table_action__btn"
              onClick={() => setInvoiceModal(row?.id)}
            >
              <MdOutlineStickyNote2 size={10} />
              Invoice
            </div>
          </Tooltip>
          <Tooltip title="Cancel Shipment">
            <div
              className={`table_action__btn ${
                row?.shipment_status?.toLowerCase() === "cancelled"
                  ? "table_action__btn__disabled"
                  : ""
              }`}
              onClick={() => setConfirmCancel(row.id)}
            >
              <MdOutlineCancel size={10} />
              Cancel
            </div>
          </Tooltip>
        </div>
      ),
      width: "350px",
      fixed: "right",
      disabled: (row) => (row?.shipment_status === "Cancelled" ? true : false),
    },
  ];
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const getAllShipments = async (page, perPage, filter, nestedSearch) => {
    const response = await fetchAllShipmentsAdmin(
      tab,
      page,
      perPage,
      filter,
      nestedSearch
    );
    if (!response.error) {
      setShipments(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleTicketOpen = (row) => {
    if (row?.tickets?.length > 0)
      Navigate({
        pathname: "/tickets-admin",
        search: `?ticket_no=${row?.tickets[0]?.ticket_no}`,
      });
    else setTicketCreateModal(row);
  };

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <Confirm
        open={confirmCancel}
        setOpen={setConfirmCancel}
        handleConfirm={() => handleCancelShipment(confirmCancel)}
        text="Do you want to cancel this shipment?"
        loader={cancelingShipment}
      />
      <InvoiceModal
        open={!!invoiceModal}
        data={invoiceModal}
        setData={setInvoiceModal}
        setOpen={setInvoiceModal}
        setShipments={setShipments}
        getAllShipments={getAllShipments}
        type="editable"
      />
      <TicketCreateModal
        open={!!ticketCreateModal}
        setOpen={setTicketCreateModal}
        previousData={ticketCreateModal}
        setSnack={setSnack}
      />

      <TabsComponent
        items={[
          {
            label: "All Carrier",
            style: { fontWeight: "bold" },
            value: "all",
          },
          {
            label: "Hajex Carriers",
            style: { fontWeight: "bold" },
            value: "Hajex",
          },
          {
            label: "Custom Carriers",
            style: { fontWeight: "bold" },
            value: "Custom",
          },
        ]}
        tab={tab}
        handleTabChange={handleTabChange}
      />

      <div className="orders_header">
        <div>
          <span className="orders_hdrheading">Shipments List</span>
        </div>
      </div>
      <div className="custom__table_size1">
        <BoltTable
          message={"No Shipments Available To Display!"}
          searchPlaceHolder={"Search Shipments"}
          getAllData={getAllShipments}
          data={shipments}
          columns={columns}
          refresh={tab}
          // expandable={true}
          // detailsComponent={(data) => (
          //   <Expandable data={data} weightUnit="Lb" volumeUnit="Cbm" />
          // )}
        />
      </div>
    </div>
  );
};

export default AdminShipments;
