import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, ButtonGroup } from "@mui/material";
import { Bar, Doughnut, PolarArea } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { FaClipboardList, FaUserFriends, FaDollarSign } from "react-icons/fa";
import DashboardCard from "../../../Components/Common/Cards/DashboardCard";
import {
  getAllDashboardCounts,
  getAllOrdersByCustomers,
  getFullfilmentCostByCustomers,
  getFullfilmentStatusByCustomers,
} from "../../../services/WarehouseServices/dashboard.services";
import { useDispatch } from "react-redux";
import { showSnackBar } from "../../../store/common/snackBarSlice";
import BarChartWithPagination from "../../../Components/Common/Charts/BarChartWithPagination";
import { colorsList } from "../../../constants/colors";
import { Skeleton } from "@mui/material";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const WarehouseDashboard = () => {
  // States for each chart's time range
  const [ordersTimeRange, setOrdersTimeRange] = useState("monthly");
  const [costTimeRange, setCostTimeRange] = useState("monthly");
  const [fulfillmentTimeRange, setFulfillmentTimeRange] = useState("monthly");
  const [counts, setCounts] = useState({
    orderCount: "",
    userCount: "",
    fulfillmentCost: "",
  });
  const [ordersByCustomers, setOrdersByCustomers] = useState();
  const [fulfillmentCosts, setFulfillmentCosts] = useState();
  const [fulfillmentStatuses, setFulfillmentStatuses] = useState();
  const [loading, setLoading] = useState({
    counts: true,
    orders: true,
    fulfillment_statuses: true,
    fulfillment_cost: true,
  });
  const dispatch = useDispatch();

  const fetchAllData = async () => {
    try {
      // Make all API calls simultaneously
      const [
        dashboardCountsResponse,
        ordersByCustomersResponse,
        fullfilmentCostResponse,
        fullfilmentStatusResponse,
      ] = await Promise.all([
        getAllDashboardCounts(),
        getAllOrdersByCustomers(ordersTimeRange),
        getFullfilmentCostByCustomers(costTimeRange),
        getFullfilmentStatusByCustomers(fulfillmentTimeRange),
      ]);

      // Handle each response
      if (dashboardCountsResponse?.data) {
        setCounts(dashboardCountsResponse?.data?.result);
        setLoading((prevLoadings) => {
          return { ...prevLoadings, counts: false };
        });
      }
      if (ordersByCustomersResponse?.data) {
        const apiResponse = ordersByCustomersResponse?.data?.result;
        const graphData = {
          labels: Object.keys(apiResponse),
          datasets: [
            {
              label: "Orders (Current)",
              data: Object.values(apiResponse)?.map((order) =>
                parseInt(order, 10)
              ),
              backgroundColor: Object.values(apiResponse)?.map(
                (_, index) => colorsList[index]
              ),
            },
          ],
        };
        setLoading((prevLoadings) => {
          return { ...prevLoadings, orders: false };
        });
        setOrdersByCustomers(graphData);
      }
      if (fullfilmentCostResponse?.data) {
        const apiResponse = fullfilmentCostResponse?.data?.result;
        const graphData = {
          labels: Object.keys(apiResponse),
          datasets: [
            {
              label: "Fulfillment Costs",
              data: Object.values(apiResponse)?.map((cost) =>
                parseInt(cost, 10)
              ),
              backgroundColor: Object.values(apiResponse)?.map(
                (_, index) => colorsList[index]
              ),
            },
          ],
        };
        setLoading((prevLoadings) => {
          return { ...prevLoadings, fulfillment_cost: false };
        });
        setFulfillmentCosts(graphData);
      }
      if (fullfilmentStatusResponse?.data) {
        const apiResponse = fullfilmentStatusResponse?.data?.result;
        const graphData = {
          labels: Object.keys(apiResponse),
          datasets: [
            {
              label: "Fulfillment Statuses",
              data: Object.values(apiResponse)?.map((status) =>
                parseInt(status, 10)
              ),
              backgroundColor: ["#4caf50", "#ffeb3b", "#f44336"],
            },
          ],
        };
        setFulfillmentStatuses(graphData);
        setLoading((prevLoadings) => {
          return { ...prevLoadings, fulfillment_statuses: false };
        });
      }

      const errors = [
        dashboardCountsResponse?.error,
        ordersByCustomersResponse?.error,
        fullfilmentCostResponse?.error,
        fullfilmentStatusResponse?.error,
      ].filter((error) => error !== null);

      if (errors.length > 0) {
        errors.forEach((error) => {
          dispatch(
            showSnackBar({
              text: error?.response?.data?.message ?? "Something went wrong",
              severity: "error",
            })
          );
        });
      }
    } catch (error) {
      // Handle any unexpected errors
      dispatch(
        showSnackBar({
          text: "An unexpected error occurred",
          severity: "error",
        })
      );
    }
  };
  const fetchOrdersByCustomers = async (timeRange) => {
    const response = await getAllOrdersByCustomers(timeRange);
    if (response?.data) {
      const apiResponse = response?.data?.result;
      const graphData = {
        labels: Object.keys(apiResponse),
        datasets: [
          {
            label: "Orders (Current)",
            data: Object.values(apiResponse).map((order) =>
              parseInt(order, 10)
            ),
            backgroundColor: Object.values(apiResponse)?.map(
              (_, index) => colorsList[index]
            ),
          },
        ],
      };
      setOrdersByCustomers(graphData);
    }
  };

  const fetchFulfillmentCosts = async (timeRange) => {
    const response = await getFullfilmentCostByCustomers(timeRange);
    if (response?.data) {
      const apiResponse = response?.data?.result;
      const graphData = {
        labels: Object.keys(apiResponse),
        datasets: [
          {
            label: "Fulfillment Costs",
            data: Object.values(apiResponse).map((cost) => parseInt(cost, 10)),
            backgroundColor: Object.values(apiResponse)?.map(
              (_, index) => colorsList[index]
            ),
          },
        ],
      };
      setFulfillmentCosts(graphData);
    }
  };

  const fetchFulfillmentStatuses = async (timeRange) => {
    const response = await getFullfilmentStatusByCustomers(timeRange);
    if (response?.data) {
      const apiResponse = response?.data?.result;
      const graphData = {
        labels: Object.keys(apiResponse),
        datasets: [
          {
            label: "Fulfillment Statuses",
            data: Object.values(apiResponse).map((status) =>
              parseInt(status, 10)
            ),
            backgroundColor: ["#4caf50", "#ffeb3b", "#f44336"],
          },
        ],
      };
      setFulfillmentStatuses(graphData);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    fetchOrdersByCustomers(ordersTimeRange);
  }, [ordersTimeRange]);

  useEffect(() => {
    fetchFulfillmentCosts(costTimeRange);
  }, [costTimeRange]);

  useEffect(() => {
    fetchFulfillmentStatuses(fulfillmentTimeRange);
  }, [fulfillmentTimeRange]);

  return (
    <Box sx={{ padding: 4, minHeight: "90vh" }}>
      <Typography variant="h6" gutterBottom>
        Warehouse Dashboard
      </Typography>
      <Grid container spacing={3}>
        {/* Cards */}
        <Grid item xs={12} md={4}>
          <DashboardCard
            title="Total Orders"
            value={counts?.orderCount ?? "0"}
            // percentage={15.2}
            icon={FaClipboardList}
            iconColor="text-blue-500"
            iconBg="bg-blue-500 bg-opacity-20"
            loading={loading?.counts}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard
            title="Total Users"
            value={counts?.userCount ?? "0"}
            // percentage={8.3}
            icon={FaUserFriends}
            iconColor="text-orange-500"
            iconBg="bg-orange-500 bg-opacity-20"
            loading={loading?.counts}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard
            title="Fulfillment Cost"
            value={counts?.orderCount ?? "0"}
            // percentage={-5.7}
            icon={FaDollarSign}
            iconColor="text-green-500"
            iconBg="bg-green-500 bg-opacity-20"
            loading={loading?.counts}
          />
        </Grid>
      </Grid>

      {/* Charts */}
      <Grid container spacing={3} className="mt-4">
        {/* Fulfillment Costs */}
        <Grid item xs={12} lg={8}>
          <Box className="p-4 bg-white rounded-lg shadow-md">
            <Box className="flex justify-between mb-4">
              <div className="text-lg font-medium text-gray-700">
                Fulfillment Costs
              </div>
              <ButtonGroup size="small" sx={{ mb: 2 }}>
                {["weekly", "monthly", "yearly"].map((range) => (
                  <Button
                    key={range}
                    variant={costTimeRange === range ? "contained" : "outlined"}
                    onClick={() => setCostTimeRange(range)}
                  >
                    {range.charAt(0).toUpperCase() + range.slice(1)}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
            {loading.fulfillment_cost ? (
              <div className="w-full h-60 bg-gray-100 animate-pulse rounded-lg"></div>
            ) : (
              fulfillmentCosts && (
                <BarChartWithPagination chartData={fulfillmentCosts} />
              )
            )}
          </Box>
        </Grid>
        {/* Fulfillment Statuses */}
        <Grid item xs={12} lg={4}>
          <Box className="p-4 bg-white rounded-lg shadow-md">
            <Box className="flex justify-between mb-4">
              <div className="text-lg font-medium text-gray-700">
                Fulfillment Statuses
              </div>
              <ButtonGroup size="small" sx={{ mb: 2 }}>
                {["weekly", "monthly", "yearly"].map((range) => (
                  <Button
                    key={range}
                    variant={
                      fulfillmentTimeRange === range ? "contained" : "outlined"
                    }
                    onClick={() => setFulfillmentTimeRange(range)}
                  >
                    {range.charAt(0).toUpperCase() + range.slice(1)}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
            {loading.fulfillment_statuses ? (
              <div className="w-full h-60 bg-gray-100 animate-pulse rounded-lg"></div>
            ) : (
              fulfillmentStatuses && (
                <Doughnut
                  data={fulfillmentStatuses}
                  options={{ responsive: true }}
                  style={{ maxHeight: "223px", margin: "0 auto" }}
                />
              )
            )}
          </Box>
        </Grid>

        <Grid item xs={12} lg={12}>
          {/* Orders by Customers */}

          <Box className="p-4 bg-white rounded-lg shadow-md">
            <Box className="flex justify-between mb-4">
              <div className="text-lg font-medium text-gray-700">
                Orders by Customer
              </div>
              <ButtonGroup size="small" sx={{ mb: 2 }}>
                {["weekly", "monthly", "yearly"].map((range) => (
                  <Button
                    key={range}
                    variant={
                      ordersTimeRange === range ? "contained" : "outlined"
                    }
                    onClick={() => setOrdersTimeRange(range)}
                  >
                    {range.charAt(0).toUpperCase() + range.slice(1)}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
            {loading.orders ? (
              <div className="w-full h-60 bg-gray-100 animate-pulse rounded-lg"></div>
            ) : (
              ordersByCustomers && (
                <BarChartWithPagination
                  chartData={ordersByCustomers}
                  height="223px"
                />
              )
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WarehouseDashboard;
