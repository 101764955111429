import React from "react";
import { FaShoppingCart, FaArrowUp, FaArrowDown } from "react-icons/fa";

const DashboardCard = ({
  title,
  value,
  percentage,
  icon: Icon,
  iconColor = "text-blue-500",
  iconBg = "bg-blue-500 bg-opacity-20",
  loading,
}) => {
  // Determine styles based on the percentage
  const isPositive = percentage >= 0;
  const percentageColor = isPositive ? "text-green-500" : "text-red-500";
  const ArrowIcon = isPositive ? FaArrowUp : FaArrowDown;

  // Generate a semi-transparent background color based on icon color
  const bgColor = iconColor.replace("text-", "");
  const style = { backgroundColor: `${bgColor}` };

  return (
    <>
      <div className="relative bg-white shadow-sm rounded-lg p-4 w-full">
        {/* Icon in the top-right corner */}
        <div className={`absolute top-4 right-4 rounded-full p-3 ${iconBg}`}>
          <Icon className={`text-lg ${iconColor}`} />
        </div>

        {/* Card Content */}
        <h2 className="text-sm font-medium text-gray-700">{title}</h2>
        {loading ? (
          <div className="w-[60%] h-[35px] rounded-md bg-gray-100 animate-pulse"></div>
        ) : (
          <p className="text-3xl font-semibold text-gray-700 mt-1">{value}</p>
        )}

        {/* Percentage Change */}
        {/* <div className="flex items-start">
        <div className={`flex items-center mt-1 ${percentageColor} mr-4`}>
          <ArrowIcon className="mr-1 text-sm" />
          <span className="text-xs font-medium">{percentage}%</span>
        </div>

        <p className="text-xs text-gray-500 mt-1">Updated: Today</p>
      </div> */}
      </div>
    </>
  );
};

export default DashboardCard;
