import React, { useEffect, useState } from "react";
import "./AddBalance.css";

// components imports
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import {
  confirmAddCard,
  getCardSetupIntent,
} from "../../../../../../../services/ShipperServices/Payments/payments.services";

// library imports

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";

const CardForm = ({ onClose, stripe, clientSecret, setCards }) => {
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState();
  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        "::placeholder": {
          color: "#aab7c4",
        },
        "& input": {
          border: "1px solid #ccc",
        },
        borderRadius: "4px",
        padding: "10px",
      },
      invalid: {
        color: "#fa755a",
      },
    },
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    // console.log(clientSecret);
    try {
      const { error, setupIntent } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );
      if (error) {
        setError(error.message);
        setLoading(false);
      } else {
        const confirm = await confirmAddCard(setupIntent?.payment_method);
        if (!confirm?.error) {
          setCards((cards) => [...cards, confirm?.data?.result]);
        }
        // console.log("Payment method added:", setupIntent, confirm);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      setError("An error occurred while adding the card.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="card__element_wrap">
        <div id="card-element" className="card__element">
          <CardElement options={cardElementOptions} />
        </div>
      </div>
      {error && <div className="strperror_text_wrap">{error}</div>}
      <div className="pmtsadd__balance_modalfooter">
        <div className="pmtsadd__balance_modalcncl" onClick={onClose}>
          Cancel
        </div>

        <button className={`pmtsadd__balance_modalsave`} type="submit">
          {loading ? <Loader /> : "Add Card"}
        </button>
      </div>
    </form>
  );
};

export default function AddCard({ open, setOpen, setSnack, setCards }) {
  const handleClose = () => {
    setOpen(false);
  };
  const user = useSelector((state) => state.user);

  const [error, setError] = useState(null);
  const [stripePromise, setStripe] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [loadingCard, setLoadingCard] = useState(false);

  useEffect(() => {
    const loadStripeFunc = async () => {
      const stripeObj = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
      setStripe(stripeObj);
    };
    const getStripeCardIntent = async () => {
      setLoadingCard(true);
      const response = await getCardSetupIntent();
      setClientSecret(response.data?.result?.client_secret);
      setLoadingCard(false);
    };
    getStripeCardIntent();
    loadStripeFunc();
  }, []);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="pmtsadd__balance_modal add__card_modal_wrap">
          <div className="pmtsadd__balance_modalheading">Add New Card</div>
          <div
            className="pmtsadd__balance_modalcontent"
            style={{ minHeight: "120px" }}
          >
            {" "}
            {clientSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CardForm
                  onClose={handleClose}
                  stripe={stripePromise}
                  clientSecret={clientSecret}
                  setCards={setCards}
                />
              </Elements>
            )}
            {!clientSecret && loadingCard && (
              <div className="loading__strpcard_form">Loading Card Form...</div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
