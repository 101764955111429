import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";

// Auth Check
import AuthRoutes from "./AuthRoutes";
// Auth Check

// Authentication
import RegisterForm from "../../Pages/Authentication/RegisterForm";
import LoginForm from "../../Pages/Authentication/LoginForm";
import Welcome from "../../Pages/Authentication/Components/Welcome/Welcome";
import GetCode from "../../Pages/ResetPassword/GetCode";
import ResetPassword from "../../Pages/ResetPassword/ResetPassword";
// Authentication

// Roles Auth
import ShipperRoutes from "./ShipperRoutes";
import CarrierRoutes from "./CarrierRoutes";
import AdminRoutes from "./AdminRoutes";
import WarehouseRoutes from "./WarehouseRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
// Roles Auth

// Shipper Routes
import Ship from "../../Pages/Shipper/Customer/MainPages/ShipPage/Ship";
import Shipments from "../../Pages/Shipper/Customer/MainPages/Shipments/Shipments";
import Invoices from "../../Pages/Shipper/Customer/MainPages/Invoices/Invoices";
import Pickup from "../../Pages/Shipper/Customer/MainPages/Pickup/Pickup";
import Tracking from "../../Pages/Shipper/Customer/MainPages/Tracking/Tracking";
import Packages from "../../Pages/Shipper/Customer/MainPages/Packages/Packages";
import Carriers from "../../Pages/Shipper/Customer/MainPages/Carriers/Carriers";
import Orders from "../../Pages/Shipper/Customer/MainPages/Orders/Orders";
import Payments from "../../Pages/Shipper/Customer/MainPages/Payments/Payments";
import Tickets from "../../Pages/Shipper/Customer/MainPages/Tickets/Tickets";
import ProductsShipper from "../../Pages/Shipper/Customer/MainPages/Products/Products";
import ProductNotAvailable from "../../Pages/Shipper/Customer/MainPages/Products/ProductNotAvailable";

import HelperUserSettings from "../../Pages/Shipper/Customer/MainPages/Settings/HelperUserSettings";
import RolesSettings from "../../Pages/Shipper/Customer/MainPages/Settings/RolesSettings";
import PermissionsSettings from "../../Pages/Shipper/Customer/MainPages/Settings/PermissionsSettings";
import Profile from "../../Pages/Shipper/Customer/NavDropdown/Profile";
import Privacypolicy from "../../Pages/Shipper/Customer/NavDropdown/Privacypolicy";
import Termsandconditions from "../../Pages/Shipper/Customer/NavDropdown/Termsandconditions";
import WareHouseSettings from "../../Pages/Shipper/Customer/MainPages/Settings/WareHouseSettings";
import Plugins from "../../Pages/Shipper/Customer/MainPages/Plugins/Plugins";
import AddNewOrderPage from "../../Pages/Shipper/Customer/MainPages/AddNewProduct/AddNewProductPage";
// Shipper Routes

// Admin Routes
import UserRegistration from "../../Pages/Admin/UserRegistration/UserRegistration";
import NewsLetter from "../../Pages/Admin/NewsLetter/NewsLetter";
import Companies from "../../Pages/Admin/Companies/Companies";
import AdminPayments from "../../Pages/Admin/Payments/Payments";
import AdminShipments from "../../Pages/Admin/AdminShipments/AdminShipments";
import AdminTickets from "../../Pages/Admin/Tickets/Tickets";
// Admin Routes

// Warehouse Routes
import Clients from "../../Pages/Warehouse/Clients/Clients";
import WarehouseOrders from "../../Pages/Warehouse/WarehouseOrders/Orders";
import AddWarehouseProduct from "../../Pages/Products/AddProduct/AddWarehouseProduct";
// import WarehouseShipment from "../../Pages/Warehouse/WarehouseShipment/Shipments";
import WarehouseDashboard from "../../Pages/Warehouse/Dashboard/WarehouseDashboard";
// import WarehouseTracking from "../../Pages/Warehouse/Tracking/Tracking";
// import WarehouseInvoices from "../../Pages/Warehouse/Invoices/Invoices";
import WarehouseProducts from "../../Pages/Warehouse/Products/Products";
// Warehouse Routes

// Carrier Routes
import Carriership from "../../Pages/Carrier/Carriership";
import Dispatcher from "../../Pages/Carrier/Dispatcher/Dispatcher";
import Drivers from "../../Pages/Carrier/Drivers/Drivers";
import CarrierInvoices from "../../Pages/Carrier/Invoices/Invoices";
import Vehicle from "../../Pages/Carrier/Vehicles/Vehicles";
import CarrierProfile from "../../Pages/Carrier/CarrierProfile/CarrierProfile";
import CreateZone from "../../Pages/Carrier/CreateZone/CreateZone";
// Carrier Routes

// Testing Routes
import Debounce from "../../Pages/Shipper/Customer/Testing/test";
import MyComponent from "../../Pages/Shipper/Customer/Testing/test2";
// Testing Routes

// Products Routes

import AddProduct from "../../Pages/Products/AddProduct/AddProduct";
import EditProduct from "../../Pages/Products/AddProduct/EditProduct";
import AddTransferNote from "../../Pages/Products/AddTransferNotes/Components/CreateTransferNote";
import Sales from "../../Pages/Sales/Sales";
import Marketplaces from "../../Pages/Shipper/Customer/MainPages/MarketPlaces/MarketPlaces";
import AdminLoginForm from "../../Pages/Authentication/AdminLoginForm";
import StockReceivedForm from "../../Pages/StockReceived/StockReceivedForm";
import StorageLocations from "../../Pages/Warehouse/StorageLocations/StorageLocations";
import TableSettings from "../../Pages/Shipper/Customer/MainPages/Settings/SystemsSettings/TableSettings";

// Products Routes

function Animatedroutes() {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route element={<AuthRoutes />}>
          {/* Shipper Routes */}
          <Route
            element={
              <ShipperRoutes mergedPermissions={user?.mergedPermissions} />
            }
          >
            <Route path="/" element={<Ship />} />
            <Route path="/shipments" element={<Shipments />} />
            <Route path="/Invoices" element={<Invoices />} />
            <Route path="/Pickup" element={<Pickup />} />
            <Route path="/Tracking" element={<Tracking />} />
            <Route
              path="/products"
              element={
                user?.user_approved_warehouses?.length > 0 ? (
                  <ProductsShipper />
                ) : (
                  <ProductNotAvailable />
                )
              }
            />
            <Route
              path="/stock-received-form"
              element={<StockReceivedForm />}
            />

            <Route path="/Packages" element={<Packages />} />
            <Route path="/Marketplaces" element={<Marketplaces />} />
            <Route path="/Carriers" element={<Carriers />} />
            <Route path="/Orders" element={<Orders />} />
            <Route path="/Payments" element={<Payments />} />
            <Route path="/Tickets" element={<Tickets />} />
            <Route
              path="/settings/manage-helper-users"
              element={<HelperUserSettings />}
            />
            <Route path="/settings/manage-roles" element={<RolesSettings />} />
            <Route
              path="/settings/manage-permissions"
              element={<PermissionsSettings />}
            />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Privacypolicy" element={<Privacypolicy />} />
            <Route
              path="/Termsandconditions"
              element={<Termsandconditions />}
            />
            <Route
              path="/settings/manage-warehouse"
              element={<WareHouseSettings />}
            />
            <Route
              path="/settings/table-pagination"
              element={<TableSettings />}
            />
            <Route path="/plugins/:plugin" element={<Plugins />} />
            <Route path="/AddNewOrderPage" element={<AddNewOrderPage />} />
            <Route path="/AddProduct" element={<AddProduct />} />
            <Route path="/edit-product" element={<EditProduct />} />
            <Route path="/AddTransferNote" element={<AddTransferNote />} />
            <Route path="/sales" element={<Sales />} />
          </Route>
          {/* Shipper Routes */}

          {/* Warehouse Routes */}
          <Route element={<WarehouseRoutes />}>
            <Route path="/warehouse-user-profile" element={<Profile />} />
            <Route
              path="/warehouse-dashboard"
              element={<WarehouseDashboard />}
            />
            <Route path="/warehouse-clients" element={<Clients />} />
            <Route path="/warehouse-orders" element={<WarehouseOrders />} />
            <Route path="/warehouse-payments" element={<Payments />} />
            <Route path="/warehouse-ship" element={<Ship />} />
            {/* <Route path="/warehouse-shipment" element={<WarehouseShipment />} /> */}
            <Route
              path="warehouse/settings/manage-helper-users"
              element={<HelperUserSettings />}
            />
            <Route
              path="warehouse/settings/manage-roles"
              element={<RolesSettings />}
            />
            <Route
              path="warehouse/settings/manage-permissions"
              element={<PermissionsSettings />}
            />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Privacypolicy" element={<Privacypolicy />} />
            <Route
              path="/Termsandconditions"
              element={<Termsandconditions />}
            />
            <Route
              path="warehouse/settings/manage-warehouse"
              element={<WareHouseSettings />}
            />
            <Route
              path="warehouse/settings/table-pagination"
              element={<TableSettings />}
            />
            {/* <Route path="/warehouse-tracking" element={<WarehouseTracking />} /> */}
            {/* <Route path="/warehouse-invoices" element={<WarehouseInvoices />} /> */}
            <Route path="/warehouse-products" element={<WarehouseProducts />} />
            <Route
              path="/warehouse-stock-received-form"
              element={<StockReceivedForm />}
            />
            <Route
              path="/warehouse-AddProduct"
              element={<AddWarehouseProduct />}
            />
            <Route path="/storage-locations" element={<StorageLocations />} />
            <Route
              path="/settings/manage-warehouse"
              element={<WareHouseSettings />}
            />
          </Route>
          {/* Warehouse Routes End*/}

          {/* Carrier Routes */}
          <Route element={<CarrierRoutes />}>
            <Route path="/Carriership" element={<Carriership />} />
            <Route path="/dispatcher" element={<Dispatcher />} />
            <Route path="/drivers" element={<Drivers />} />
            <Route path="/carrier-invoices" element={<CarrierInvoices />} />
            <Route path="/vehicles" element={<Vehicle />} />
            <Route path="/carrier-profile" element={<Profile />} />
            <Route path="/create-zone" element={<CreateZone />} />
            <Route
              path="carrier/settings/manage-helper-users"
              element={<HelperUserSettings />}
            />
            <Route
              path="carrier/settings/manage-roles"
              element={<RolesSettings />}
            />
            <Route
              path="carrier/settings/manage-permissions"
              element={<PermissionsSettings />}
            />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Privacypolicy" element={<Privacypolicy />} />
            <Route
              path="/Termsandconditions"
              element={<Termsandconditions />}
            />
            <Route
              path="carrier/settings/manage-warehouse"
              element={<WareHouseSettings />}
            />
            <Route
              path="carrier/settings/table-pagination"
              element={<TableSettings />}
            />
          </Route>
          {/* Carrier Routes End*/}

          {/* Admin Routes */}
          <Route element={<AdminRoutes />}>
            <Route path="/Users" element={<UserRegistration />} />
            <Route path="/user/Newsletter" element={<NewsLetter />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/payments-admin" element={<AdminPayments />} />
            <Route path="/shipments-admin" element={<AdminShipments />} />
            <Route path="/tickets-admin" element={<AdminTickets />} />
            <Route
              path="admin/settings/manage-helper-users"
              element={<HelperUserSettings />}
            />
            <Route
              path="admin/settings/manage-roles"
              element={<RolesSettings />}
            />
            <Route
              path="admin/settings/manage-permissions"
              element={<PermissionsSettings />}
            />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Privacypolicy" element={<Privacypolicy />} />
            <Route
              path="/Termsandconditions"
              element={<Termsandconditions />}
            />
            <Route
              path="admin/settings/manage-warehouse"
              element={<WareHouseSettings />}
            />
            <Route
              path="admin/settings/table-pagination"
              element={<TableSettings />}
            />
          </Route>
        </Route>
        {/* Admin Routes */}

        {/* Authentication Routes */}
        <Route element={<AuthenticationRoutes />}>
          <Route path="/Register" element={<RegisterForm />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/admin-login" element={<AdminLoginForm />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/get-code" element={<GetCode />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
        {/* Authentication Routes */}

        {/* Testing Routes */}
        <Route path="/test" element={<Debounce />} />
        <Route path="/test2" element={<MyComponent />} />
        {/* Testing Routes */}
      </Routes>
    </AnimatePresence>
  );
}

export default Animatedroutes;
