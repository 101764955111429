import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const BarChartWithPagination = ({
  chartData,
  recordsPerPage = 15,
  height = "223px",
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (chartData?.labels && chartData?.datasets) {
      const totalRecords = chartData.labels.length;
      const pages = Math.ceil(totalRecords / recordsPerPage);
      setTotalPages(pages);

      // Paginate the labels and data
      const startIndex = (currentPage - 1) * recordsPerPage;
      const endIndex = startIndex + recordsPerPage;

      // Truncate labels for display while keeping the original labels for tooltips
      const paginatedLabels = chartData.labels.slice(startIndex, endIndex);
      const truncatedLabels = paginatedLabels.map((label) =>
        label.length > 6 ? `${label.slice(0, 6)}...` : label
      );

      const paginatedDatasets = chartData.datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data.slice(startIndex, endIndex),
      }));

      setPaginatedData({
        labels: truncatedLabels, // Truncated labels for display
        fullLabels: paginatedLabels, // Full labels for tooltips
        datasets: paginatedDatasets,
      });
    }
  }, [chartData, currentPage, recordsPerPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <Box sx={{ position: "relative", backgroundColor: "white" }}>
      {paginatedData && (
        <Bar
          data={{
            ...paginatedData,
            labels: paginatedData.labels, // Truncated labels for x-axis
          }}
          style={{ height, width: "100%" }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                callbacks: {
                  // Remove the title from the tooltip
                  title: () => {
                    return ""; // No title in tooltip
                  },
                  // Show full label and value
                  label: (context) => {
                    const fullLabel =
                      paginatedData.fullLabels[context.dataIndex];
                    const value = context.raw;

                    return `${fullLabel}: ${value}`;
                  },
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  callback: function (value, index) {
                    // Use truncated labels on the x-axis
                    return paginatedData.labels[index];
                  },
                  // Add custom hover behavior for x-axis labels
                  afterBuildTicks: (scale) => {
                    const tickElements = scale.ticks.map((tick, index) => {
                      const fullLabel = paginatedData.fullLabels[index];
                      return { label: fullLabel };
                    });

                    scale.ticks.forEach((tick, i) => {
                      // Add full-label-hover logic here
                    });
                  },
                },
              },
            },
          }}
        />
      )}

      {/* Pagination Arrows */}
      <Box
        sx={{
          position: "absolute",
          top: -30,
          right: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          size="small"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          sx={{ mx: 0.5 }}
        >
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
        <Typography variant="body2" sx={{ mx: 0.5 }}>
          {`${currentPage}/${totalPages}`}
        </Typography>
        <IconButton
          size="small"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          sx={{ mx: 0.5 }}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default BarChartWithPagination;
