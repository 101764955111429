import axios from "../../axiosInstance";

export const fetchAllShipmentsAdmin = async (
  tab,
  page,
  perPage,
  filter,
  Nested
) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `admin/get-shipments/${tab}?perPage=${perPage}&page=${page}&name=${filter}${
        Nested ?? ""
      }`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const cancelShipment = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`cancel-shipment/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
