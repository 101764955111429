import { Outlet, Navigate } from "react-router-dom";

import NavBar from "../Navbar/ShipperNavBar";
let currentPath = window.location.pathname;
const shipperPermissions = [
  { title: "Ship", url: "/", name: "shipper.access.shipment.create" },
  {
    title: "Shipments",
    url: "/shipments",
    name: "shipper.access.shipment.view",
  },
  {
    title: "Invoices",
    url: "/Invoices",
    name: "shipper.access.invoice.view",
  },
  {
    title: "Pickup",
    url: "/Pickup",
    name: "shipper.access.pickup.view",
  },
  {
    title: "Tracking",
    url: "/Tracking",
    name: "shipper.access.tracking.view",
  },
  {
    title: "Products",
    url: "/Packages",
    name: "shipper.access.product.view",
  },
  {
    title: "MarketPlaces",
    url: "/Marketplaces",
    name: "",
  },
  {
    title: "Carriers",
    url: "/Carriers",
    name: "shipper.access.carrier.view",
  },
  {
    title: "Orders",
    url: "/orders",
    name: "shipper.access.order.view",
  },
  {
    title: "Payments",
    url: "/payments",
    name: "shipper.access.payment.view",
  },
];

const PrivateRoutes = (props) => {
  let role = localStorage.getItem("Role");
  if (role === "Shipper" || role === "ShipperUser") {
    if (
      role === "Shipper"
        ? true
        : props?.mergedPermissions?.includes(
            shipperPermissions.find((p) => p?.url === currentPath)?.name
          )
    ) {
      return (
        <>
          <NavBar />
          <Outlet />
        </>
      );
    } else {
      return <Navigate to="/unauthorized" />;
    }
  } else if (role === "Carrier") {
    return <Navigate to="/create-zone" />;
  } else if (role === "Admin") {
    return <Navigate to="/Users" />;
  } else if (role === "warehouseUser") {
    return <Navigate to="/warehouse-dashboard" />;
  } else {
    return <Navigate to="/Login" />;
  }
};

export default PrivateRoutes;
