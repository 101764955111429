import axios from "../axiosInstance";

export const getAllDashboardCounts = async () => {
    let response = { data: null, error: null };
    try {
        const res = await axios.get(
            `warehouse/dashboard/get-count`
        );
        response = { ...response, data: res.data };
    } catch (error) {
        response = { ...response, error: error };
    }
    return response;
};

export const getAllOrdersByCustomers = async (type = "yearly") => {
    let response = { data: null, error: null };
    try {
        const res = await axios.get(
            `warehouse/dashboard/orders-count-by-customer?type=${type}`
        );
        response = { ...response, data: res.data };
    } catch (error) {
        response = { ...response, error: error };
    }
    return response;
};
export const getFullfilmentCostByCustomers = async (type = "yearly") => {
    let response = { data: null, error: null };
    try {
        const res = await axios.get(
            `warehouse/dashboard/orders-fullfillment-cost-by-customer?type=${type}`
        );
        response = { ...response, data: res.data };
    } catch (error) {
        response = { ...response, error: error };
    }
    return response;
};
export const getFullfilmentStatusByCustomers = async (type = "yearly") => {
    let response = { data: null, error: null };
    try {
        const res = await axios.get(
            `warehouse/dashboard/orders-fullfillment-status-total?type=${type}`
        );
        response = { ...response, data: res.data };
    } catch (error) {
        response = { ...response, error: error };
    }
    return response;
};
