import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CiMenuKebab } from "react-icons/ci";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  MdHistory,
  MdEditCalendar,
  MdOutlineEditCalendar,
} from "react-icons/md";
import { AiOutlineBarcode } from "react-icons/ai";

export default function CustomMenu({
  row,
  rowIndex,
  handleFieldChange,
  setProdDetailsModal,
  setProdHistoryModal,
  setLabelModal,
  navigate,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="table_action__wrap">
      <div className="table_action__btn" onClick={handleClick}>
        <CiMenuKebab size={10} style={{ marginRight: "2px" }} />
        More
      </div>
      <Menu
        id={`basic-menu-${row.id}`} // Unique ID for each row
        anchorEl={anchorEl}
        open={open}
        size="small"
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `basic-button-${row.id}`, // Unique ID for each row
          "box-shadow": "none",
        }}
      >
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setProdDetailsModal({ row, rowIndex, handleFieldChange });
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdEditCalendar
              size={15}
              style={{
                headerColor: "#0C6EFD",
                cursor: "pointer",
              }}
            />
          </ListItemIcon>
          <ListItemText>View</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            navigate("/warehouse-AddProduct", { state: row });
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdOutlineEditCalendar size={15} />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setProdHistoryModal({ row, rowIndex, handleFieldChange });
            handleClose();
          }}
        >
          <ListItemIcon>
            <MdHistory
              size={15}
              style={{
                cursor: "pointer",
              }}
            />
          </ListItemIcon>
          <ListItemText>History</ListItemText>
        </MenuItem>
        <MenuItem
          className="boltTable__action_more_btn_label"
          onClick={() => {
            setLabelModal({ row, rowIndex, handleFieldChange });
            handleClose();
          }}
        >
          <ListItemIcon>
            <AiOutlineBarcode size={15} />
          </ListItemIcon>
          <ListItemText>Barcode</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
