import "./AddBalance.css";
import "./StripeCreditCardForm.css";

// components imports
import Loader from "../../../../../../../Components/CircularLoader/CircularLoader";
import { updateUser } from "../../../../../../../store/userSlice";
// apis imports
import {
  chargeAmount,
  confirmAddCardWithCharge,
  getCards,
  getCardSetupIntent,
} from "../../../../../../../services/CreditCard/stripe.services";
import { fixedDecimals } from "../../../../../../../helpers/helpers";

// library imports
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { FaArrowAltCircleLeft } from "react-icons/fa";

export default function StripeCreditCardForm({
  setOpen,
  setSnack,
  setActiveBox,
  getAllTransactions,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [creditCardChargeAmount, setCreditCardChargeAmount] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [addOtherCard, setAddOtherCard] = useState(false);
  const [cards, setCards] = useState(false);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [clientSecret, setClientSecret] = useState(null); // used for stripe credit card setup
  const [paymentMethodId, setPaymentMethodId] = useState(null);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.card_last_four) getCardsList();
  }, []);

  const getCardsList = async () => {
    setLoading(true);
    let response;
    try {
      response = await getCards();
      setCards(response.data.result.payment_methods);
    } catch (error) {
      // console.log(error);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
    setLoading(false);
  };

  const getCreditCardSetup = async () => {
    setLoading(true);
    let response;
    try {
      response = await getCardSetupIntent();
      setClientSecret(response.data.result.client_secret);
      // console.log("client_secret", response.data.result.client_secret);
    } catch (error) {
      // console.log(error);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
    setLoading(false);
  };

  const addNewCharge = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    try {
      const response = await chargeAmount(
        creditCardChargeAmount,
        paymentMethodId
      );
      //console.log('response => ', response.error.response.data.message);
      // console.log("Old Card Resp", response?.data?.result?.balance);
      if (response.error) {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response.error.response.data.message,
            severity: "error",
          };
        });
      } else {
        getAllTransactions(1, true);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Your balance added via your credit card.",
            severity: "success",
          };
        });
        dispatch(
          updateUser({
            balance: response?.data?.result?.balance,
          })
        );

        setActiveBox(1);
        setOpen(false);
      }
      setIsProcessing(false);
    } catch (error) {
      // console.log(error);
      setIsProcessing(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const response = await stripe.confirmSetup({
      elements,
      confirmParams: {},
      redirect: "if_required",
    });

    //console.log(response);

    if (
      (response.error && response.error.type === "card_error") ||
      (response.error && response.error.type === "validation_error")
    ) {
      setMessage(response.error.message);
      setIsProcessing(false);
    } else if (response.setupIntent.payment_method) {
      //display success message or redirect user
      // console.log("stripe payment completion response =>", response);
      // call backend add card and charge amount
      storeCardAndChargeAmount(
        response.setupIntent.payment_method,
        creditCardChargeAmount
      );
    }
  };

  const storeCardAndChargeAmount = async (
    payment_method_id,
    creditCardChargeAmount
  ) => {
    setIsProcessing(true);
    let response;
    try {
      response = await confirmAddCardWithCharge(
        payment_method_id,
        creditCardChargeAmount
      );
      getAllTransactions(1, true);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Your balance added via your credit card.",
          severity: "success",
        };
      });
      // console.log("New Card Resp", response?.data?.result?.balance);
      // dispatch(updateUser(response?.data?.result));
      dispatch(
        updateUser({
          balance: response?.data?.result?.balance,
        })
      );
      setActiveBox(1);
      setOpen(false);
      //window.location.reload(false);
    } catch (error) {
      setIsProcessing(false);
      // console.log(error);
    }
    setIsProcessing(false);
  };

  return (
    <>
      <form
        id="payment-form"
        onSubmit={
          !user.card_last_four || addOtherCard ? handleSubmit : addNewCharge
        }
        style={{
          // marginTop: ( !user.card_last_four || addOtherCard ) ?  "200px" : "5px",
          display: "block",
          position: "relative",
          padding: "0 3px",
          width: "100%",
          paddingBottom: "15px",
          minHeight: "fit-content",
        }}
        className="payAmount__form_box"
      >
        <div className="payAmount__field_wrap">
          <label className="payAmount__field_label">Amount (CAD)</label>
          <input
            type="text"
            onChange={(e) => setCreditCardChargeAmount(e.target.value)}
            className="pmtsadd__balance_modalinput"
            style={{ marginBottom: "7px" }}
          />
        </div>
        <div className="payAmount__field_wrap">
          <label
            className="payAmount__field_label"
            style={{ fontWeight: "bold" }}
          >
            Total Available Amount (CAD)
          </label>
          <input
            type="text"
            value={
              creditCardChargeAmount > 0
                ? parseFloat(creditCardChargeAmount) +
                  " - " +
                  (parseFloat(creditCardChargeAmount) * 0.03).toFixed(2) +
                  " (3% Payment Processing Fee) " +
                  " = " +
                  (
                    parseFloat(creditCardChargeAmount) -
                    parseFloat(creditCardChargeAmount) * 0.03
                  ).toFixed(2)
                : 0
            }
            className="pmtsadd__balance_modalinput"
            style={{ marginBottom: "7px", fontWeight: "bold" }}
            disabled
          />
        </div>
        <div className="payViaCard_main_wrap">
          {(!user.card_last_four || addOtherCard) && (
            <PaymentElement id="payment-element" />
          )}

          {user.card_last_four &&
            cards.length > 0 &&
            addOtherCard == false &&
            cards?.map((card) => {
              return (
                <div className="card__main_box_wrap">
                  <div className="card__main_box">
                    <input
                      type="radio"
                      name="radio_card"
                      onChange={() => setPaymentMethodId(card.id)}
                      defaultChecked={card.card.is_default}
                      className="card__main_box_radio"
                    />
                    <div className="card__main_box_header">
                      <h2>Charge amount using this </h2>
                      <strong style={{ textTransform: "capitalize" }}>
                        {card.card.brand}
                      </strong>
                    </div>
                    <div className="card__main_box_cardNum">
                      {"xxxx xxxx xxxx "}
                      {card.card.last4}
                    </div>

                    {card.card?.is_default == true ? (
                      <div className="card__main_box_default_tag">Default</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}

          {loading ? (
            <div className="loader__card_wrap">
              <Loader />
            </div>
          ) : (
            ""
          )}
        </div>
        {cards.length > 0 && addOtherCard == false && (
          <div className="newCard__main_wrapper">
            <input
              type="radio"
              name="radio_card"
              onChange={() => setAddOtherCard(true)}
              style={{ marginRight: "5px" }}
            />
            <span onClick={() => setAddOtherCard(true)}>
              Pay using a new card
            </span>
          </div>
        )}

        <div className="pmtsadd__strpcard_btnwrap">
          <button
            disabled={isProcessing || !stripe || !elements || loading}
            id="submit"
            className="pmtsadd__strpcard_btn"
          >
            <span id="button-text paynow__btn">
              {isProcessing ? "Processing ... " : "Pay now"}
            </span>
          </button>
        </div>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
      <div className="pmtsadd__balance_modalbbtnwrap">
        <button
          onClick={() => {
            if (addOtherCard) {
              setAddOtherCard(false);
              setIsProcessing(false);
            } else setActiveBox(1);
          }}
          className="pmtsadd__balance_modalbackbtn"
          style={{ margin: "0 auto" }}
        >
          <FaArrowAltCircleLeft size={15} color="grey" />
          Back
        </button>
      </div>
    </>
  );
}
