import React, { useState } from "react";
import "./CreditCard.css";

// components imports
import Confirm from "../../../../../../../Components/Popups/Confirm";

// apis imports
// library imports
import CircularProgress from "@mui/material/CircularProgress";
import { MdDelete } from "react-icons/md";

const CreditCard = ({
  id,
  last_digits,
  brand,
  exp_month,
  exp_year,
  isDefault,
  handleDefaultCard,
  handleDeleteCard,
  deleteLoader,
  loading,
  total,
}) => {
  const [deleteConfirm, setDeleteConfirm] = useState();
  return (
    <div className="credit__card_wrap">
      <Confirm
        text="Do you want to delete this card?"
        open={!!deleteConfirm}
        setOpen={setDeleteConfirm}
        loader={deleteLoader}
        handleConfirm={() => handleDeleteCard(deleteConfirm, isDefault)}
      />
      {(!isDefault || total === 1) && (
        <MdDelete
          size={24}
          className="credit__card_del"
          onClick={() => setDeleteConfirm(id)}
        />
      )}
      <div className="credit__card_brand">{brand?.toUpperCase()}</div>
      <div className="credit___card_number">
        xxxx xxxx xxxx {last_digits ?? "xxxx"}
      </div>
      <div className="credit___card_date">
        Exp. Date: {exp_month < 10 ? `0${exp_month}` : exp_month}/
        {`${exp_year}`[2] + `${exp_year}`[3]}
      </div>

      {!isDefault && (
        <button
          href="#"
          className="credit___card_btn"
          onClick={() => {
            handleDefaultCard(id);
          }}
        >
          {!loading && " Select as default"}
          {loading && (
            <CircularProgress size={20} style={{ color: "#C1C1C1" }} />
          )}
        </button>
      )}
      {isDefault && (
        <span href="#" className="credit___card_default">
          Default
        </span>
      )}
    </div>
  );
};

export default CreditCard;
