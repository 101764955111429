import { Outlet, Navigate } from "react-router-dom";
import WarehouseNavBar from "../Navbar/WarehouseNavBar";
const WarehouseRoutes = () => {
  let role = localStorage.getItem("Role");

  if (role === "warehouseUser") {
    return (
      <>
        <WarehouseNavBar />
        <Outlet />
      </>
    );
  } else if (role === "Carrier") {
    return <Navigate to="/create-zone" />;
  } else if (role === "Admin") {
    return <Navigate to="/Users" />;
  } else if (role === "warehouseUser") {
    return <Navigate to="/warehouse-dashboard" />;
  } else {
    return <Navigate to="/Login" />;
  }
};

export default WarehouseRoutes;
