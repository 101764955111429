export const units = {
  lb: "Pound",
  kg: "Kilogram",
};
export const sizeUnits = {
  in: "Inch",
  cm: "Centimeter",
};
export const fullUnit = (unit) => {
  let u = unit?.toLowerCase();
  return units[u];
};
export const fullSizeUnit = (unit) => {
  let u = unit?.toLowerCase();
  return sizeUnits[u];
};
export const removeDuplicates = (arr) => {
  return arr.filter((item, index) => item && arr.indexOf(item) === index);
};
export const lbsToKgs = (weightInLbs) => {
  let weight = Number(weightInLbs);
  if (isNaN(weight)) {
    return 0;
  }
  return Number(weight * 0.45359237).toFixed(2);
};
export const kgsToLbs = (weightInKgs) => {
  let weight = Number(weightInKgs);
  if (isNaN(weight)) {
    return 0;
  }
  return Number(weight / 0.45359237).toFixed(2);
};

export const cbmToCbf = (cbm) => {
  if (typeof cbm !== "number") {
    cbm = Number(cbm);
    if (isNaN(cbm)) return 0;
  }
  return (cbm * 35.3146667).toFixed(2);
};

export const cbfToCbm = (cbf) => {
  if (typeof cbf !== "number") {
    cbf = Number(cbf);
    if (isNaN(cbf)) return 0;
  }
  return (cbf / 35.3146667).toFixed(2);
};

export function truncateString(str, limit) {
  if (typeof str !== "string") {
    return "-";
  }
  if (str.length > limit) {
    return str.substring(0, limit) + "...";
  } else {
    return str;
  }
}

export const setdata = (fn, date = false, defaultValue = "N/A") => {
  try {
    if (date === true) {
      return fn() != null ? fn().split("T")[0].split(" ")[0] : defaultValue;
    } else {
      return fn() != null ? fn() : defaultValue;
    }
  } catch {
    return defaultValue;
  }
};
export const removeUnderscoresAndCapitalize = (str) => {
  if (str) {
    const words = str.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  } else {
    return "";
  }
};

export const generateUniqueId = () => {
  const timestamp = new Date().getTime();
  const randomNum = Math.floor(Math.random() * 10000);
  return `${timestamp}-${randomNum}`;
};

export const toCamelCase = (str) => {
  // Convert all characters to lowercase
  str = str.toLowerCase();

  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const fixedDecimals = (number, decimalLimit = 2) => {
  const inputNumber = typeof number === "string" ? parseFloat(number) : number;

  const decimalPlaces = (inputNumber.toString().split(".")[1] || []).length;
  if (decimalPlaces > decimalLimit) {
    const restrictedNumber = inputNumber.toFixed(decimalLimit);
    return restrictedNumber;
  }

  return inputNumber;
};

export const capitalize = (str) => {
  if (str.length === 0) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const marketplacesFormat = (str) => {
  // Convert to lowercase for case-insensitive comparison
  str = str
    ?.trim()
    ?.replace(/[\s\uFEFF\xA0]+/g, "")
    ?.toLowerCase();
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const checkFileType = (file) => {
  // Define lists of allowed file extensions
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];
  const excelExtensions = [
    ".xls",
    ".xlsx",
    ".xlsm",
    ".xlsb",
    ".xlt",
    ".xltx",
    ".xltm",
    ".csv",
    ".sheet",
  ];
  const odfExtensions = [".odt", ".ods", ".odp", ".odg", ".odf"];
  const pdfExtensions = [".pdf"];
  const documentExtensions = [
    ".doc",
    ".docx",
    ".ppt",
    ".pptx",
    ".pptm",
    ".rtf",
    ".txt",
    ".document",
  ];

  // Get the file extension
  const extension = file?.substring(file.lastIndexOf(".")).toLowerCase();
  // Check if the extension matches any of the allowed types
  if (imageExtensions.includes(extension)) {
    return "Image";
  } else if (excelExtensions.includes(extension)) {
    return "Excel";
  } else if (odfExtensions.includes(extension)) {
    return "ODF";
  } else if (pdfExtensions.includes(extension)) {
    return "PDF";
  } else if (documentExtensions.includes(extension)) {
    return "Document";
  } else {
    return "Unsupported"; // If none of the above, file type is unsupported
  }
};


export function sortObjectsArray(arr, fieldName = "order_by", order = 'ascending') {
  // Check if the array is valid and has elements
  if (!Array.isArray(arr) || arr.length === 0) return [];

  // Sorting logic
  return arr.sort((a, b) => {
    // Check if the field exists in both objects
    if (!a.hasOwnProperty(fieldName) || !b.hasOwnProperty(fieldName)) return 0;

    let comparison = 0;

    // Compare based on the field value
    if (a[fieldName] > b[fieldName]) {
      comparison = 1;
    } else if (a[fieldName] < b[fieldName]) {
      comparison = -1;
    }

    // Reverse the order if descending is specified
    return order === 'descending' ? comparison * -1 : comparison;
  });
}

export function findSettingByType(settingsArray, settingType) {
  return settingsArray?.find(
    (setting) => setting.setting_type === settingType
  );
}

export const generateRandomColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, "0")}`;