export const colorsList = [
    "#3182bd", "#9ecae1", "#e6550d", "#fdae6b", "#31a354",
    "#a1d99b", "#756bb1", "#bcbddc", "#636363", "#bdbdbd",
    "#6baed6", "#c6dbef", "#fd8d3c", "#fdd0a2", "#74c476",
    "#c7e9c0", "#9e9ac8", "#dadaeb", "#969696", "#d9d9d9",
    "#3182bd", "#fdae6b", "#a1d99b", "#636363", "#6baed6",
    "#fd8d3c", "#c6dbef", "#74c476", "#d9d9d9", "#e6550d",
    "#bcbddc", "#31a354", "#969696", "#9e9ac8", "#756bb1",
    "#fdd0a2", "#dadaeb", "#bdbdbd", "#c7e9c0", "#6baed6",
    "#c6dbef", "#fdae6b", "#74c476", "#fd8d3c", "#31a354",
    "#d9d9d9", "#9e9ac8", "#e6550d", "#bcbddc", "#969696"
]

