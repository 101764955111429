import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./FinalQuoteModel.css";
import Modal from "react-bootstrap/Modal";
import { setdata } from "../../../../../../helpers/helpers";
import Button from "@mui/material/Button";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getCardSetupIntent } from "../../../../../../services/CreditCard/stripe.services";
import StripeCreditCardForm from "./StripeCreditCardForm";
import CircularLoader from "../../../../../../Components/CircularLoader/CircularLoader";

export default function FinalQuoteModel(props) {
  const { StepCheck, SetStepCheck, Flist, Tlist, CreateShipment, Plist } =
    props;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [clientSecret, setClientSecret] = useState(null); // used for stripe credit card setup
  const [paymentType, setPaymentType] = useState("pay_via_balance"); // or pay via card
  const [addOtherCard, setAddOtherCard] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [loading, setLoading] = useState(false);
  const childRef = useRef();
  const [globalEventHolder, setGlobalEventHolder] = useState(null);

  useEffect(() => {
    if (
      parseFloat(StepCheck["SingleQuoteData"]["user_balance"]) <
      parseFloat(StepCheck["SingleQuoteData"]["total"]) + 0
    )
      setPaymentType("pay_via_card");
    if (
      StepCheck["SingleQuoteData"]["payment_methods"]?.length == 0 &&
      paymentType === "pay_via_card"
    ) {
      setAddOtherCard(true);
      getCreditCardSetup();
    }
    if (
      parseFloat(StepCheck["SingleQuoteData"]["user_balance"]) <
        parseFloat(StepCheck["SingleQuoteData"]["total"]) &&
      StepCheck["SingleQuoteData"]["payment_methods"]?.length == 0
    ) {
      setAddOtherCard(true);
      getCreditCardSetup();
    }
    if (StepCheck["SingleQuoteData"]["payment_methods"]?.length > 0) {
      let default_payment_method = StepCheck["SingleQuoteData"][
        "payment_methods"
      ].find((card) => card.card.is_default == true);
      setPaymentMethodId(default_payment_method.id);
    }
  }, [
    StepCheck["SingleQuoteData"]["user_balance"],
    StepCheck["SingleQuoteData"]["payment_methods"],
  ]);

  const getCreditCardSetup = async () => {
    setLoading(true);
    let response;
    try {
      response = await getCardSetupIntent();
      setClientSecret(response.data.result.client_secret);
    } catch (error) {
      console.log(error);
      alert(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (addOtherCard === false && StepCheck["credit_card_payment_method_id"]) {
      CreateShipment(globalEventHolder);
    }
  }, [StepCheck["credit_card_payment_method_id"], globalEventHolder]);

  useEffect(() => {
    if (paymentType === "pay_via_balance") {
      SetStepCheck((prevStepCheck) => ({
        ...prevStepCheck,
        credit_card_payment_method_id: "",
      }));
    }
  }, [paymentType]);

  return (
    <Modal
      show={StepCheck["Final_Order"]}
      // onHide={() => { setfinalorder(false); }}
      size="lg"
      centered
      className="modal__shipment_confirmation"
    >
      <Modal.Header>
        <Modal.Title>Shipment Confirmation</Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-0">
        {StepCheck["Final_Order"] === true && (
          <>
            <Row>
              <Col md={6}>
                <Row
                  style={{
                    backgroundColor: "#1976D2",
                    textAlign: "center",
                    color: "#ffffff",
                  }}
                >
                  <b className="modal__shipment_confirmation_sec_title">
                    {" "}
                    Ship From{" "}
                  </b>
                </Row>
                <Row className="my-1">
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Name:</b>
                    </small>
                  </Col>
                  <Col md={8}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Flist["attention"]}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Company:</b>
                    </small>
                  </Col>
                  <Col md={8}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Flist["company"]}
                    </small>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Address:</b>
                    </small>
                  </Col>
                  <Col md={8} style={{ whiteSpace: "pre-line" }}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Flist["address"]}
                    </small>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Country:</b>
                    </small>
                  </Col>
                  <Col md={8} style={{ whiteSpace: "pre-line" }}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Flist["country"]}
                    </small>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>State:</b>
                    </small>
                  </Col>
                  <Col md={8} style={{ whiteSpace: "pre-line" }}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Flist["state"]}
                    </small>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>City:</b>
                    </small>
                  </Col>
                  <Col md={8} style={{ whiteSpace: "pre-line" }}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Flist["city"]}
                    </small>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Zip/Postal:</b>
                    </small>
                  </Col>
                  <Col md={8} style={{ whiteSpace: "pre-line" }}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Flist["zip"]}
                    </small>
                  </Col>
                </Row>

                <Row className="my-1">
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Phone:</b>
                    </small>
                  </Col>
                  <Col md={8}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Flist["phone"]}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Email:</b>
                    </small>
                  </Col>
                  <Col md={8}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Flist["email"]}
                    </small>
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                <Row
                  style={{
                    backgroundColor: "#1976D2",
                    textAlign: "center",
                    color: "#ffffff",
                  }}
                >
                  <b className="modal__shipment_confirmation_sec_title">
                    {" "}
                    Ship To{" "}
                  </b>
                </Row>
                <Row className="my-1">
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Name:</b>
                    </small>
                  </Col>
                  <Col md={8}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Tlist["attention"]}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Company:</b>
                    </small>
                  </Col>
                  <Col md={8}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Tlist["company"]}
                    </small>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Address:</b>
                    </small>
                  </Col>
                  <Col md={8} style={{ whiteSpace: "pre-line" }}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Tlist["address"]}
                    </small>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Country:</b>
                    </small>
                  </Col>
                  <Col md={8} style={{ whiteSpace: "pre-line" }}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Tlist["country"]}
                    </small>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>State:</b>
                    </small>
                  </Col>
                  <Col md={8} style={{ whiteSpace: "pre-line" }}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Tlist["state"]}
                    </small>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>City:</b>
                    </small>
                  </Col>
                  <Col md={8} style={{ whiteSpace: "pre-line" }}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Tlist["city"]}
                    </small>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Zip/Postal:</b>
                    </small>
                  </Col>
                  <Col md={8} style={{ whiteSpace: "pre-line" }}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Tlist["zip"]}
                    </small>
                  </Col>
                </Row>

                <Row className="my-1">
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Phone:</b>
                    </small>
                  </Col>
                  <Col md={8}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Tlist["phone"]}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1 mb-1">
                  <Col md={4}>
                    <small className="modal__shipment_confirmation_list_title">
                      <b>Email:</b>
                    </small>
                  </Col>
                  <Col md={8}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Tlist["email"]}
                    </small>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              style={{
                backgroundColor: "#1976D2",
                textAlign: "center",
                // border: "1px solid black",
                color: "#ffffff",
              }}
            >
              <small>
                <b className="modal__shipment_confirmation_sec_title">
                  Shipment Breakdown
                </b>
              </small>
            </Row>

            <Row>
              <Col md={6}>
                <Row className="my-1">
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      <b>Carrier: </b>
                    </small>
                  </Col>
                  <Col md={6}>
                    <img
                      src={setdata(
                        () => StepCheck["SingleQuoteData"]["img"],
                        false,
                        ""
                      )}
                      style={{ width: 99, height: 38 }}
                    />
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      <b>Service Type: </b>
                    </small>
                  </Col>
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {setdata(
                        () => StepCheck["SingleQuoteData"]["service_type"],
                        false
                      )}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      <b>No. of Packages: </b>
                    </small>
                  </Col>
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {Plist.length}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      <b>Total Weight: </b>
                    </small>
                  </Col>
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {StepCheck["TotalWeight"]}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      <b>Est Transit days: </b>
                    </small>
                  </Col>
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {setdata(
                        () => StepCheck["SingleQuoteData"]["transit_days"],
                        false
                      )}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      <b>Est Delivery date: </b>
                    </small>
                  </Col>
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {setdata(
                        () => StepCheck["SingleQuoteData"]["delivery_date"],
                        true
                      )}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      <b>Base Charge: </b>
                    </small>
                  </Col>
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {setdata(
                        () => StepCheck["SingleQuoteData"]["base_charge"],
                        false
                      )}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      <b>Total Charge: </b>
                    </small>
                  </Col>
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {setdata(
                        () => StepCheck["SingleQuoteData"]["total"],
                        false
                      )}
                    </small>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row style={{ textAlign: "center" }}>
                  <small className="modal__shipment_confirmation_list_detail">
                    <b>Additional Charges</b>
                  </small>
                </Row>
                <Row className="my-1">
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      <b>Residential Charge: </b>
                    </small>
                  </Col>
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {setdata(
                        () =>
                          StepCheck["SingleQuoteData"]["residential_charge"],
                        false
                      )}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      <b>Signature Charge: </b>
                    </small>
                  </Col>
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {setdata(
                        () => StepCheck["SingleQuoteData"]["signature_charge"],
                        false
                      )}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      <b>Fuel Charge: </b>
                    </small>
                  </Col>
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {setdata(
                        () => StepCheck["SingleQuoteData"]["fuel_charge"],
                        false
                      )}
                    </small>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      <b>Freight Charge: </b>
                    </small>
                  </Col>
                  <Col md={6}>
                    <small className="modal__shipment_confirmation_list_detail">
                      {setdata(
                        () => StepCheck["SingleQuoteData"]["freight_charge"],
                        false
                      )}
                    </small>
                  </Col>
                </Row>
                {StepCheck?.SingleQuoteData?.tailgate_charge && (
                  <>
                    <Row className="my-1">
                      <Col md={6}>
                        <small>
                          <b>Tailgate Charge: </b>
                        </small>
                      </Col>
                      <Col md={6}>
                        <small>
                          {setdata(
                            () =>
                              StepCheck["SingleQuoteData"]["tailgate_charge"],
                            false
                          )}
                        </small>
                      </Col>
                    </Row>
                  </>
                )}
                {StepCheck?.SingleQuoteData?.insurance_charge && (
                  <>
                    <Row className="my-1">
                      <Col md={6}>
                        <small className="modal__shipment_confirmation_list_detail">
                          <b>Insurance Charge: </b>
                        </small>
                      </Col>
                      <Col md={6}>
                        <small className="modal__shipment_confirmation_list_detail">
                          {setdata(
                            () =>
                              StepCheck["SingleQuoteData"]["insurance_charge"],
                            false
                          )}
                        </small>
                      </Col>
                    </Row>
                  </>
                )}
                {/* {StepCheck?.SingleQuoteData?.service_charge > 0 ? (
                  <>
                    <Row className="my-1">
                      <Col md={6}>
                        <small className="modal__shipment_confirmation_list_detail">
                          <b>Service Charge: </b>
                        </small>
                      </Col>
                      <Col md={6}>
                        <small className="modal__shipment_confirmation_list_detail">
                          {StepCheck?.SingleQuoteData?.service_charge ?? ""}
                        </small>
                      </Col>
                    </Row>
                  </>
                ) : null} */}
                <Row style={{ textAlign: "center" }}>
                  <small className="modal__shipment_confirmation_list_detail">
                    <b>Tax Charges</b>
                  </small>
                </Row>
                {StepCheck["SingleQuoteData"]["tax_charges"]?.map(
                  (tax, index) => (
                    <Row className="my-1" key={index}>
                      <Col md={6}>
                        <small className="modal__shipment_confirmation_list_detail">
                          <b>{tax.NameEn}: </b>
                        </small>
                      </Col>
                      <Col md={6}>
                        <small className="modal__shipment_confirmation_list_detail">
                          {tax.Charge}
                        </small>
                      </Col>
                    </Row>
                  )
                )}
              </Col>
            </Row>

            {/* {StepCheck["SingleQuoteData"]["response_from"] == "Via Bolt" ||
              StepCheck["SingleQuoteData"]["account_type"] == "client"} */}

            {(StepCheck["SingleQuoteData"]["response_from"] === "Via Bolt" ||
              StepCheck["SingleQuoteData"]["account_type"] === "client") && (
              <>
                <Row
                  style={{
                    backgroundColor: "#1976D2",
                    textAlign: "center",
                    // border: "1px solid black",
                    color: "#ffffff",
                  }}
                >
                  <small>
                    <b className="modal__shipment_confirmation_sec_title">
                      Payment Information
                    </b>
                  </small>
                </Row>

                <Row>
                  <Col md={12}>
                    {parseFloat(StepCheck["SingleQuoteData"]["user_balance"]) <
                      0 && (
                      <Row className="my-1">
                        <Col md={6}>
                          <small className="modal__shipment_confirmation_sec_title">
                            <b>Outstanding Balance: </b>
                          </small>
                        </Col>
                        <Col md={6}>
                          <small
                            className="modal__shipment_confirmation_sec_title"
                            style={{ color: "red" }}
                          >
                            {setdata(
                              () =>
                                parseFloat(
                                  StepCheck["SingleQuoteData"]["user_balance"]
                                ) * -1,
                              false
                            )}
                          </small>
                        </Col>
                      </Row>
                    )}
                    {parseFloat(StepCheck["SingleQuoteData"]["user_balance"]) <
                      0 && (
                      <Row className="my-1">
                        <Col md={6}>
                          <small className="modal__shipment_confirmation_sec_title">
                            <b>Charge Amount of Current Shipment: </b>
                          </small>
                        </Col>
                        <Col md={6}>
                          <small className="modal__shipment_confirmation_sec_title">
                            {setdata(
                              () => StepCheck["SingleQuoteData"]["total"],
                              false
                            )}
                          </small>
                        </Col>
                      </Row>
                    )}
                    <Row className="my-1">
                      <Col md={6}>
                        <small className="modal__shipment_confirmation_sec_title">
                          <b>Total Amount to Pay: </b>
                        </small>
                      </Col>
                      <Col md={6}>
                        <small
                          className="modal__shipment_confirmation_sec_title"
                          style={{ fontWeight: "bold" }}
                        >
                          {setdata(
                            () =>
                              (
                                parseFloat(
                                  StepCheck["SingleQuoteData"]["total"]
                                ) +
                                0 +
                                parseFloat(
                                  StepCheck["SingleQuoteData"]["user_balance"] <
                                    0
                                    ? parseFloat(
                                        StepCheck["SingleQuoteData"][
                                          "user_balance"
                                        ]
                                      ) * -1
                                    : 0
                                )
                              ).toFixed(2),
                            false
                          )}
                          {paymentType == "pay_via_card" && (
                            <span>
                              {" "}
                              +{" "}
                              {(
                                (parseFloat(
                                  StepCheck["SingleQuoteData"]["total"]
                                ) +
                                  0 +
                                  parseFloat(
                                    StepCheck["SingleQuoteData"][
                                      "user_balance"
                                    ] < 0
                                      ? parseFloat(
                                          StepCheck["SingleQuoteData"][
                                            "user_balance"
                                          ]
                                        ) * -1
                                      : 0
                                  )) *
                                0.03
                              ).toFixed(2)}{" "}
                              (3% Payment Processing Fee) ={" "}
                              {(
                                parseFloat(
                                  StepCheck["SingleQuoteData"]["total"]
                                ) +
                                0 +
                                parseFloat(
                                  StepCheck["SingleQuoteData"]["user_balance"] <
                                    0
                                    ? parseFloat(
                                        StepCheck["SingleQuoteData"][
                                          "user_balance"
                                        ]
                                      ) * -1
                                    : 0
                                ) +
                                (parseFloat(
                                  StepCheck["SingleQuoteData"]["total"]
                                ) +
                                  0 +
                                  parseFloat(
                                    StepCheck["SingleQuoteData"][
                                      "user_balance"
                                    ] < 0
                                      ? parseFloat(
                                          StepCheck["SingleQuoteData"][
                                            "user_balance"
                                          ]
                                        ) * -1
                                      : 0
                                  )) *
                                  0.03
                              ).toFixed(2)}{" "}
                            </span>
                          )}
                        </small>
                      </Col>
                    </Row>

                    <Row className="my-1">
                      <Col md={12}>
                        <small
                          className="modal__shipment_confirmation_sec_title"
                          style={{ fontWeight: "bold" }}
                        >
                          <div
                            style={{
                              color:
                                parseFloat(
                                  StepCheck["SingleQuoteData"]["user_balance"]
                                ) <
                                parseFloat(
                                  StepCheck["SingleQuoteData"]["total"]
                                ) +
                                  0
                                  ? "darkgray"
                                  : "black",
                            }}
                          >
                            <input
                              type="radio"
                              value="pay_via_balance"
                              checked={paymentType === "pay_via_balance"}
                              onChange={() => {
                                setPaymentType("pay_via_balance");
                                setAddOtherCard(false);
                              }}
                              disabled={
                                parseFloat(
                                  StepCheck["SingleQuoteData"]["user_balance"]
                                ) <
                                parseFloat(
                                  StepCheck["SingleQuoteData"]["total"]
                                )
                              }
                              name="pay-radio"
                            />{" "}
                            Pay via Account Balance (
                            <span
                              style={{
                                color:
                                  parseFloat(
                                    StepCheck["SingleQuoteData"]["user_balance"]
                                  ) < 0
                                    ? "red"
                                    : "",
                              }}
                            >
                              {StepCheck["SingleQuoteData"]["user_balance"]}
                            </span>
                            )
                            {parseFloat(
                              StepCheck["SingleQuoteData"]["user_balance"]
                            ) <
                              parseFloat(
                                StepCheck["SingleQuoteData"]["total"]
                              ) +
                                0 && (
                              <span style={{ color: "red" }}>
                                {" "}
                                Insufficient funds
                              </span>
                            )}{" "}
                          </div>
                          <input
                            type="radio"
                            name="pay-radio"
                            value={"pay_via_card"}
                            checked={paymentType === "pay_via_card"}
                            onChange={() => {
                              setPaymentType("pay_via_card");
                              if (
                                StepCheck["SingleQuoteData"]["payment_methods"]
                                  .length == 0
                              ) {
                                setAddOtherCard(true);
                                getCreditCardSetup();
                              }
                            }}
                          />{" "}
                          Pay via Credit Card
                        </small>
                      </Col>
                    </Row>

                    <Row className="my-1">
                      <Col md={12} style={{ marginLeft: "15px" }}>
                        <div className="payViaCard_main_wrap">
                          {paymentType === "pay_via_card" &&
                            StepCheck["SingleQuoteData"]["payment_methods"]
                              .length > 0 &&
                            StepCheck["SingleQuoteData"][
                              "payment_methods"
                            ]?.map((card, index) => {
                              return (
                                <div
                                  className="card__main_box_wrap"
                                  key={index}
                                >
                                  <div className="card__main_box">
                                    <input
                                      type="radio"
                                      name="radio_card"
                                      onChange={() => {
                                        setPaymentMethodId(card.id);
                                        setAddOtherCard(false);
                                      }}
                                      defaultChecked={card.card.is_default}
                                      className="card__main_box_radio"
                                    />
                                    <div className="card__main_box_header">
                                      <h2>Charge amount using this </h2>
                                      <strong
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {card.card.brand}
                                      </strong>
                                    </div>
                                    <div className="card__main_box_cardNum">
                                      {"xxxx xxxx xxxx "}
                                      {card.card.last4}
                                    </div>

                                    {card.card?.is_default == true ? (
                                      <div className="card__main_box_default_tag">
                                        Default
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          {StepCheck["SingleQuoteData"]["payment_methods"]
                            .length > 0 &&
                            paymentType === "pay_via_card" && (
                              <div className="newCard__main_wrapper">
                                <input
                                  type="radio"
                                  name="radio_card"
                                  checked={addOtherCard === true}
                                  onChange={() => {
                                    setAddOtherCard(true);
                                    getCreditCardSetup();
                                  }}
                                  style={{ marginRight: "5px" }}
                                />

                                <span
                                  onClick={() => {
                                    setAddOtherCard(true);
                                    getCreditCardSetup();
                                  }}
                                >
                                  Pay using a new card
                                </span>
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        {loading ? (
                          <div>
                            {" "}
                            <CircularLoader />{" "}
                          </div>
                        ) : (
                          ""
                        )}
                        {paymentType === "pay_via_card" &&
                          addOtherCard &&
                          stripePromise &&
                          clientSecret && (
                            <Elements
                              stripe={stripePromise}
                              options={{ clientSecret }}
                            >
                              <StripeCreditCardForm
                                ref={childRef}
                                StepCheck={StepCheck}
                                SetStepCheck={SetStepCheck}
                                CreateShipment={CreateShipment}
                              />
                            </Elements>
                          )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}

            {/* <Row>
              <Col md="2">
                <Row
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <small>
                    <b>Carrier</b>
                  </small>
                </Row>
              </Col>
              <Col md="2">
                <Row
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <small>
                    <b>Total Weight</b>
                  </small>
                </Row>
              </Col>
              <Col md="2">
                <Row
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <small>
                    <b>Est Days</b>
                  </small>
                </Row>
              </Col>
              <Col md="3">
                <Row
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <small>
                    <b>Est. Delivery Date</b>
                  </small>
                </Row>
              </Col>
              <Col md="2">
                <Row
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <small>
                    <b>Base Charge</b>
                  </small>
                </Row>
              </Col>
              <Col md="1">
                <Row
                  style={{
                    textAlign: "center",
                    // borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <small>
                    <b>Total</b>
                  </small>
                </Row>
              </Col>
            </Row> */}

            {/* <Row>
              <Col md="2">
                <Row
                  style={{
                    justifyContent: "center",
                    borderRight: "1px solid black",
                    borderTop: "1px solid black",
                  }}
                  // className="py-2"
                >
                  <img
                    src={setdata(
                      () => StepCheck["SingleQuoteData"]["img"],
                      false,
                      ""
                    )}
                    style={{ width: 99, height: 25 }}
                  />
                </Row>
              </Col>
              <Col md="2">
                <Row
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid black",
                    borderTop: "1px solid black",
                  }}
                  // className="py-2"
                >
                  <small className="mt-1 mb-0">
                    {StepCheck["TotalWeight"]}
                  </small>
                </Row>
              </Col>
              <Col md="2">
                <Row
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid black",
                    borderTop: "1px solid black",
                  }}
                  // className="py-2"
                >
                  <small className="mt-1 mb-0">
                    {setdata(
                      () => StepCheck["SingleQuoteData"]["transit_days"],
                      false
                    )}
                  </small>
                </Row>
              </Col>
              <Col md="3">
                <Row
                  style={{
                    textAlign: "center",
                    borderTop: "1px solid black",
                    borderRight: "1px solid black",
                  }}
                  // className="py-2"
                >
                  <small className="mt-1 mb-0">
                    {setdata(
                      () => StepCheck["SingleQuoteData"]["delivery_date"],
                      true
                    )}
                  </small>
                </Row>
              </Col>
              <Col md="2">
                <Row
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid black",
                    borderTop: "1px solid black",
                  }}
                  // className="py-2"
                >
                  <small className="mt-1 mb-0">
                    {setdata(
                      () => StepCheck["SingleQuoteData"]["base_charge"],
                      false
                    )}
                  </small>
                </Row>
              </Col>
              <Col md="1">
                <Row
                  style={{
                    textAlign: "center",
                    borderTop: "1px solid black",
                  }}
                  // className="py-2"
                >
                  <small className="mt-1 mb-0">
                    {setdata(
                      () => StepCheck["SingleQuoteData"]["total"],
                      false
                    )}
                  </small>
                </Row>
              </Col>
            </Row> */}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="modal__shipment_confirmation_disclamer_wrap footer__disclamer_wrap">
          <strong>Disclaimer:</strong> Your use of the site is governed by the{" "}
          <a href="https://hajexbolt.com/terms" target="_blank">
            Terms
          </a>{" "}
          of Use. In accordance with the Terms, Carriers are solely responsible
          for providing accurate and reliable information and services to
          Shippers. Shippers agree to hold Bolt harmless from any claims of
          liability or damage, including consequential damages, arising from
          booking or shipment of freight. We recommend that you consult the
          Terms of Use and the Default Rates Table to understand and your rights
          and obligations when using the Site.
          {StepCheck["SingleQuoteData"]["comp_name"] == "Canpar" &&
            StepCheck["SingleQuoteData"]["response_from"] != "Via Bolt" &&
            StepCheck["SingleQuoteData"]["account_type"] != "default" && (
              <>
                <br />
                <strong>Custom Account:</strong>
                You're booking under the "
                <b>{StepCheck["SingleQuoteData"]["response_from"]}</b>" custom
                account, shipments will be processed from your registered
                address by default. For shipments from a different pickup
                address, please use our <b>'Via Bolt'</b> Account during the
                booking process to specify the desired pickup location.
              </>
            )}
        </div>

        <div className="button__shipment_confirmation_wrap">
          {((StepCheck["SingleQuoteData"]["response_from"] != "Via Bolt" &&
            StepCheck["SingleQuoteData"]["account_type"] != "client") ||
            (parseFloat(StepCheck["SingleQuoteData"]["user_balance"]) >=
              parseFloat(StepCheck["SingleQuoteData"]["total"]) &&
              paymentType == "pay_via_balance")) && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ borderColor: "#0c69f3", borderRadius: "3px" }}
              type="submit"
              onClick={CreateShipment}
              disabled={StepCheck?.Disable === "final"}
              disableElevation
            >
              {StepCheck?.Disable === "final" ? <CircularLoader /> : "Accept"}
            </Button>
          )}

          {(StepCheck["SingleQuoteData"]["response_from"] == "Via Bolt" ||
            StepCheck["SingleQuoteData"]["account_type"] == "client") &&
            addOtherCard && (
              <Button
                positive
                form="payment-form"
                content="Submit"
                onClick={(event) => childRef.current.fireSubmit(event)}
                variant="contained"
                color="primary"
                size="small"
                style={{ borderColor: "#0c69f3", borderRadius: "3px" }}
                disabled={StepCheck?.Disable === "final"}
                disableElevation
              >
                {StepCheck?.Disable === "final" ? <CircularLoader /> : "Accept"}
              </Button>
            )}

          {(StepCheck["SingleQuoteData"]["response_from"] == "Via Bolt" ||
            StepCheck["SingleQuoteData"]["account_type"] == "client") &&
            addOtherCard == false &&
            paymentType === "pay_via_card" && (
              <Button
                positive
                form="payment-form"
                content="Submit"
                onClick={(event) => {
                  SetStepCheck((prevStepCheck) => ({
                    ...prevStepCheck,
                    credit_card_payment_method_id: paymentMethodId,
                  }));
                  setGlobalEventHolder(event);
                }}
                variant="contained"
                color="primary"
                size="small"
                style={{ borderColor: "#0c69f3", borderRadius: "3px" }}
                disabled={StepCheck?.Disable === "final"}
                disableElevation
              >
                {StepCheck?.Disable === "final" ? <CircularLoader /> : "Accept"}
              </Button>
            )}

          <Button
            variant="contained"
            color="error"
            size="small"
            style={{
              marginRight: "5px",
              marginLeft: "10px",
              borderRadius: "3px",
            }}
            onClick={() => {
              SetStepCheck((prev) => ({
                ...prev,
                Final_Order: false,
              }));
            }}
            disabled={StepCheck?.Disable === "final"}
            disableElevation
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
